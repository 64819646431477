import React from 'react';
import Link from 'gatsby-link';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { MDXRenderer } from "gatsby-plugin-mdx"
import Breadcrumbs from '../components/breadcrumbs';

export default function Template({ data, pageContext }) {
    const article = data.mdx;

    return (
        <Layout currentPath={pageContext.slug} mainMenuStructure={pageContext.mainMenuStructure}>
            {article.frontmatter.breadcrumbs === true && (
                <Breadcrumbs currentPath={pageContext.slug} allNodes={pageContext.allNodes} pageStructure={pageContext.mainMenuStructure} />
            )}

            <SEO title={article.frontmatter.title} />
            <h1>{article.frontmatter.title}</h1>
            <MDXRenderer>{article.body}</MDXRenderer>
            <hr className={"last-updated-sep"} />
            <small>Last updated on {article.frontmatter.date}</small>
        </Layout>
    )
}

export const articleQuery = graphql`
    query ArticleByPath($path: String!) {
        mdx(frontmatter: { path: { eq: $path }, published: { eq: true } }) {
            body
            frontmatter {
                path
                title
                author
                date
                breadcrumbs
            }
        }
    }
`;